




























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import { CategoryFaq, CategoryTree, useStructuredData } from '~/composables';

export default defineComponent({
  name: 'CategoryFaq',
  props: {
    category: {
      type: Object as PropType<CategoryTree>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { collectFaqJsonLd } = useStructuredData();

    const faq = computed<CategoryFaq>(() => props.category.faq || {});
    const categoryData = computed(() => faq.value.category_data || {});
    const isCategoryFaq = computed(() => !props.category.additional_content && categoryData.value.product_url !== '/' && categoryData.value.yield_text);
    const groupName = computed(() => `${props.category.breadcrumbs?.[1]?.category_name} ${props.category.name}`);
    const faqContent = computed(() => {
      let answer = `The ${groupName.value} uses <a href='${categoryData.value.product_url}'>${categoryData.value.short_name}</a> cartridges.`;

      if (categoryData.value.yield_text) {
        answer += ` ${categoryData.value.yield_text}.`;
      }

      return {
        question: `What ${faq.value.category_prefix || ''} does the ${groupName.value} use?`,
        answer,
      };
    });
    const jsonLd = computed(() => {
      const structuredData = {
        mainEntity: [{
          '@type': 'Question',
          name: faqContent.value.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: faqContent.value.answer,
          },
        }],
      };

      return collectFaqJsonLd(structuredData);
    });

    return {
      faq,
      categoryData,
      groupName,
      jsonLd,
      isCategoryFaq,
    };
  },
});
